module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"e-value solutions","short_name":"e-value-solutions","start_url":"/","background_color":"#4299e1","theme_color":"#4299e1","display":"minimal-ui","icon":"src/images/favicon.jpg"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"e-value-solutions-com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
